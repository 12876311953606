export default [
  {
    // 门店会员拉新
    path: 'newMemberStatistic',
    name: 'newMemberStatistic',
    meta: {
      permissionCode: 'cp-group-CRSDataReportForm',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-board/new-member-statistic/index.vue'),
  },
  {
    // 订单数据看板
    path: 'orderDataBoard',
    name: 'orderDataBoard',
    meta: {
      permissionCode: 'cp-group-orderBoard',
      routerType: 2,
    },
    component: () => import('../views/group-management/order-board/order-data-board/index.vue'),
  },
  {
    // 订单数据分析
    path: 'orderDataAnalysis',
    name: 'orderDataAnalysis',
    meta: {
      permissionCode: 'cp-group-orderBoard',
      routerType: 2,
    },
    component: () => import('../views/group-management/order-board/order-data-analysis/index.vue'),
  },
  {
    // 订单数据分析
    path: 'orderDataAnalysis/orderFailDataDetail',
    name: 'orderFailDataDetail',
    meta: {
      permissionCode: 'cp-group-orderBoard',
      routerType: 2,
    },
    component: () => import('../views/group-management/order-board/order-data-analysis/order-fail-data-detail.vue'),
  },

  {
    // CRS操作日志
    path: 'crsOperateLog',
    name: 'crsOperateLog',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-monitoring/crs-operate-log/index.vue'),
  },
  {
    // 渠道操作日志
    path: 'channelLog',
    name: 'channelLog',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 2,
      keepAlive: true,
      deepth: 1,
    },
    component: () => import('../views/group-management/data-monitoring/channel-log/index.vue'),
  },
  {
    // *查看渠道推送日志详情
    path: 'channelLogDetail',
    name: 'channelLogDetail',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/data-monitoring/channel-log/channel-content-dialog.vue'),
  },
  {
    // 数据同步日志
    path: 'synchronizationLog',
    name: 'synchronizationLog',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 2,
      keepAlive: true,
      deepth: 1,
    },
    component: () => import('../views/group-management/data-monitoring/synchronization-log/index.vue'),
  },
  {
    // *查看数据同步日志详情
    path: 'synchronizationLogDetail',
    name: 'synchronizationLogDetail',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/data-monitoring/synchronization-log/channel-content-dialog.vue'),
  },
  {
    // 火眼场景
    path: 'fireEyeScene',
    name: 'fireEyeScene',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-monitoring/fire-eye-scene/index.vue'),
  },
  {
    // 订单监控看板
    path: 'orderMonitorLog',
    name: 'orderMonitorLog',
    meta: {
      permissionCode: 'cp-order-monitor',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/data-monitoring/order-monitor/index.vue'),
  },
  {
    // 策略监控 官网分销策略监控
    path: 'strategyMonitor',
    name: 'strategyMonitor',
    meta: {
      permissionCode: 'cp-strategy-monitor',
      routerType: 2,
      keepAlive: true,
    },
    component: () => import('../views/group-management/data-monitoring/strategy-monitor/index.vue'),
  },
  {
    // 价格一致性监控-明细
    path: 'priceConsistencyDetail',
    name: 'priceConsistencyDetail',
    meta: {
      permissionCode: 'cp-priceConsistencyMonitor',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-monitoring/price-consistency-monitor/price-consistency-detail/index.vue'),
  },
  {
    // 价格一致性监控-汇总
    path: 'priceConsistencySummary',
    name: 'priceConsistencySummary',
    meta: {
      permissionCode: 'cp-priceConsistencyMonitor',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-monitoring/price-consistency-monitor/price-consistency-summary/index.vue'),
  },
  {
    // 价格一致性监控-分析
    path: 'priceConsistencyAnalysis',
    name: 'priceConsistencyAnalysis',
    meta: {
      permissionCode: 'cp-priceConsistencyMonitor',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-monitoring/price-consistency-monitor/price-consistency-analysis/index.vue'),
  },
  // 智能定价预警
  {
    // 预警列表
    path: 'warningList',
    name: 'warningList',
    meta: {
      permissionCode: 'cp-intelligentPriceWarning',
      routerType: 2,
    },
    component: () => import('../views/group-management/warning-info/warning-list/index.vue'),
  },
  {
    // 公告列表
    path: 'noticeList',
    name: 'noticeList',
    meta: {
      permissionCode: 'cp-intelligentPriceWarning',
      routerType: 2,
    },
    component: () => import('../views/group-management/warning-info/notice-list/index.vue'),
  },
  {
    // 公告列表-详情
    path: 'noticeBuild',
    name: 'noticeBuild',
    meta: {
      permissionCode: 'cp-intelligentPriceWarning',
      routerType: 2,
    },
    component: () => import('../views/group-management/warning-info/notice-list/notice-build/index.vue'),
  },

  {
    // 配置同期日期
    path: 'settingSameDate',
    name: 'settingSameDate',
    meta: {
      permissionCode: 'cp-settingSameDate',
      routerType: 2,
    },
    component: () => import('../views/group-management/setting-same-date/index.vue'),
  },
];
