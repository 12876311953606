
// crs组前缀
const crsPrefix = '/crs_api';
// 直销组前缀
const rezenPrefix = '/api';
// nodeJs接口前缀
const cbsApiPrefix = '/cbs_api';

const crsApiMapping = {
  // 获取拉新会员数据统计
  hotelReferReport: `${crsPrefix}/lps/stat/hotelReferReport`,
  // GET 导出会员拉新统计数据
  expertHotelReferReport: `${crsPrefix}/lps/stat/expertHotelReferReport`,
  // 数据监控 获取渠道日志详情
  getPushPriceInventoryDetailLog: `${crsPrefix}/log/distribution/thirdpartlog/getPushPriceInventoryDetailLog`,
  // 获取日志请求/响应报文
  getDistributionThirdPartLogRequestResponseInfo: `${crsPrefix}/log/distribution/thirdpartlog/getDistributionThirdPartLogRequestResponseInfo`,
  getPushDetailLog: `${crsPrefix}/log/distribution/thirdpartlog/getPushDetailLog`,
  // 数据监控  获取日志列表
  getDistributionThirdPartLogList: `${crsPrefix}/log/distribution/thirdpartlog/getDistributionThirdPartLogList`,
  // 数据同步日志  获取操作类型
  getDataSynchronizationLogOperateType: `${crsPrefix}/log/data/synchronization/getDataSynchronizationLogOperateType`,
  // 数据同步日志  查询日志列表
  getDataSynchronizationLogList: `${crsPrefix}/log/data/synchronization/getDataSynchronizationLogList`,
  // 数据同步日志  查询日志详情
  getDataSynchronizationLogDetailLog: `${crsPrefix}/log/data/synchronization/getDataSynchronizationLogDetailLog`,
  // 数据监控
  getDistributionThirdPartLogOperateType: `${crsPrefix}/log/distribution/thirdpartlog/getDistributionThirdPartLogOperateType`,
  // 数据监控
  queryCrsOperateLog: `${crsPrefix}/log/crsOperateLog/queryCrsOperateLog`,
  // 数据监控
  queryAllCrsOperateApi: `${crsPrefix}/log/crsOperateLog/queryAllCrsOperateApi`,
  // POST 火眼日志-获取集团渠道
  getCrsChannelList: `${crsPrefix}/merchant/crs/channel/getCrsChannelList`,
  // 火眼日志-获取渠道下酒店列表
  getCrsChannelHotelList: `${crsPrefix}/merchant/crs/channelhotel/getCrsChannelHotelList`,
  // 火眼日志-获取酒店的所有房型
  getAllRoomTypeByHotelId: `${crsPrefix}/merchant/crs/roomtype/getAllRoomTypeByHotelId`,
  // 火眼日志-获取产品列表
  getCrsProductList: `${crsPrefix}/merchant/crs/product/getCrsProductList`,
  // 获取火眼日志列表
  queryFireeyeLog: `${crsPrefix}/merchant/fireeye/queryFireeyeLog`,
  // 获取火眼日志模块类型
  queryFireeyeModuleType: `${crsPrefix}/merchant/fireeye/queryFireeyeModuleType`,
  // 获取火眼日志详情
  queryFireeyeLogDetail: `${crsPrefix}/merchant/fireeye/queryFireeyeLogDetail`,
  // 获取火眼操作类型列表
  queryFireeyeOperateType: `${crsPrefix}/merchant/fireeye/queryFireeyeOperateType`,
  // 获取火眼常用配置列表
  queryFireeyeFrontFilterList: `${crsPrefix}/merchant/fireeye/queryFireeyeFrontFilterList`,
  // 保存火眼常用配置
  saveFireeyeFrontFilter: `${crsPrefix}/merchant/fireeye/saveFireeyeFrontFilter`,
  // 删除火眼常用配置
  deleteFireeyeFrontFilter: `${crsPrefix}/merchant/fireeye/deleteFireeyeFrontFilter`,
  // 获取火眼场景订单
  getOrderInfo: `${crsPrefix}/merchant/fireeye/getOrderInfo`,
  // 获取火眼场景可订数据
  queryBookCheckLogList: `${crsPrefix}/merchant/fireeye/queryBookCheckLogList`,
  // 在线预订查询产品价格列表信息
  queryProductPriceList: `${crsPrefix}/merchant/fireeye/scence/queryProductPriceList`,
  // 在线预订查询产品价格详情信息
  queryProductPriceDetail: `${crsPrefix}/merchant/fireeye/scence/queryProductPriceDetail`,
  // 获取火眼场景携程自动Mapping数据
  getCtripProductMappingListByTime: `${crsPrefix}/merchant/fireeye/getCtripProductMappingListByTime`,
  // 火眼-通用场景操作日志
  queryCtripRoomStatusLogList: `${crsPrefix}/merchant/fireeye/queryCtripRoomStatusLogList`,
  // POST 火眼查询历史价格
  queryPromotionAndAppPriceLog: `${crsPrefix}/merchant/fireeye/queryPromotionAndAppPriceLog`,

  // 获取预警列表
  selectRmsAlertList: `${crsPrefix}/rms/config/selectRmsAlertList`,
  // 新建预警
  saveAlert: `${crsPrefix}/rms/config/saveAlert`,
  // 预警详情
  alertDetail: `${crsPrefix}/rms/config/alertDetail`,
  // RMS预警类型列表
  queryRmsAlertTypeList: `${crsPrefix}/rms/config/queryRmsAlertTypeList`,
  // 修改预警
  updateAlert: `${crsPrefix}/rms/config/updateAlert`,
  // 修改预警状态
  updateAlertStatus: `${crsPrefix}/rms/config/updateAlertStatus`,
  // 日志查询
  // queryCrsOperateLog: `${crsPrefix}/log/crsOperateLog/queryCrsOperateLog`,
  // 公告详情
  announcementDetail: `${crsPrefix}/rms/config/announcementDetail`,
  // 星级列表
  queryStarList: `${crsPrefix}/rms/config/queryStarList`,


  // 新建公告
  saveAnnouncement: `${crsPrefix}/rms/config/saveAnnouncement`,
  // 修改公告
  updateAnnouncement: `${crsPrefix}/rms/config/updateAnnouncement`,


  // 获取公告列表
  selectRmsAnnouncementList: `${crsPrefix}/rms/config/selectRmsAnnouncementList`,
  // 修改公告状态
  updateAnnouncementStatus: `${crsPrefix}/rms/config/updateAnnouncementStatus`,
  // 公告显示状态列表
  announceRunningStatusList: `${crsPrefix}/rms/config/announceRunningStatusList`,
  // 修改公告排序
  updateAnnouncementSeq: `${crsPrefix}/rms/config/updateAnnouncementSeq`,
  // 有丽呈酒店的城市列表-api
  queryCityList: `${crsPrefix}/rms/config/queryCityList`,
  // 查询日历列表
  queryCalendarList: `${crsPrefix}/rms/config/queryCalendarList`,
  // 日历导入
  importCalendar: `${crsPrefix}/rms/config/importCalendar`,
  // 获取导入模版文件
  getImportCalendarTemplate: `${crsPrefix}/rms/config/getImportCalendarTemplate`,
  // 日历修改接口
  editCalendar: `${crsPrefix}/rms/config/editCalendar`,
  // 日历下载接口
  exportCalendar: `${crsPrefix}/rms/config/exportCalendar`,
  // 火场场景订单视图
  queryFireeyeOrderInfoView: `${crsPrefix}/merchant/fireeye/scence/queryFireeyeOrderInfoView`,
  // 火场场景订单日志
  queryFireeyeOrderLogView: `${crsPrefix}/merchant/fireeye/scence/queryFireeyeOrderLogView`,
  // 订单房量追踪
  queryOrderInventoryTrackList: `${crsPrefix}/merchant/fireeye/scence/queryOrderInventoryTrackList`,
  // 订单房价追踪
  queryOrderPriceTrackList: `${crsPrefix}/merchant/fireeye/scence/queryOrderPriceTrackList`,
  // 订单查询-房价构成
  queryOrderPriceDetail: `${crsPrefix}/merchant/fireeye/scence/queryOrderPriceDetail`,
  // 获取结构化日志
  queryStructuredLog: `${crsPrefix}/log/structuredLog/queryStructuredLog`,
  // 查询会员等级
  getAllMemberRank: `${crsPrefix}/merchant/lps/memberrank/getAllMemberRank`,
  // 查询图表
  queryChart: `${crsPrefix}/rms/ordermonitor/queryChart`,

  // 官网分销
  // 查询官网分销策略监控列表
  queryOfficialDistributionMonitoringList: `${crsPrefix}/crs/merchant/official/distribution/queryOfficialDistributionMonitoringList`,
  // crs2.0查询酒店的价格level和折扣
  getHotelPriceLevelList: `${crsPrefix}/crs/merchant/pricingstrategy/getHotelPriceLevelList`,
  // 查询官网分销模式列表接口
  queryOfficialDistributionList: `${crsPrefix}/crs/merchant/official/distribution/queryOfficialDistributionList`,
  // 查询官网分销策略监控-每日收益
  queryOfficialDistributionMonitoringDailyRevenue: `${crsPrefix}/crs/merchant/official/distribution/queryOfficialDistributionMonitoringDailyRevenue`,
  // 有效的酒店列表
  getValidHotelList: `${crsPrefix}/unit/getHotelList`,
};

const cbsApiMapping = {
  // 价格一致性获取酒店列表
  getHotelList: `${cbsApiPrefix}/fireeye/getHotelList`,
  // 价格一致性获取价格类型
  getPriceTypeList: `${cbsApiPrefix}/fireeye/getPriceTypeList`,
  // 价格一致性生成渠道价格
  generateChannelPrice: `${cbsApiPrefix}/fireeye/generateChannelPrice`,
  // 价格一致性查询渠道价格
  queryChannelPrice: `${cbsApiPrefix}/fireeye/queryChannelPrice`,
  // 价格一致性渠道价格汇总
  channelPriceSummary: `${cbsApiPrefix}/fireeye/channelPriceSummary`,
  // 价格一致性渠道价格分析
  channelPriceAnalysis: `${cbsApiPrefix}/fireeye/channelPriceAnalysis`,
};

const rezenApiMapping = {
  // 获取用户关联酒店信息
  getUserRelationHotelsInfo: `${rezenPrefix}/merchant/product/hotel/getUserRelationHotelsInfo`,
  // 数据监控
  listUnit: `${rezenPrefix}/unit/listUnit`,
  // 数据监控
  queryStaffByDisplayName: `${rezenPrefix}/staff/queryStaffByDisplayName`,
  // 品牌列表
  getBrandList: `${rezenPrefix}/merchant/product/hotel/getBrandList`,
  // 酒店列表
  searchHotels: `${rezenPrefix}/merchant/product/hotel/searchHotels`,
};

// api映射关系
export default {
  ...crsApiMapping,
  ...rezenApiMapping,
  ...cbsApiMapping,
};
